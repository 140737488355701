import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoBagAddOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const OwnedWeb = () => {
    const [rentedWebs, setRentedWebs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://templateapi.xyz/template/rented')
                .then((e) => setRentedWebs(e.data.data))
                .catch((e) => console.log(e))
                .finally(() => setIsLoading(false))
        }
    }, [isLoading])

    if (isLoading) {
        return <div className="loader-container"><div className="loader"></div></div>
    }

    return (
        <div className='flex flex-wrap justify-start items-center w-full gap-5 max-md:gap-1'>
            {rentedWebs.map((e) => (
                <div className='w-[25vw] flex flex-col gap-2 h-[30vh] max-md:w-[42vw] max-md:h-[15vh]'>
                    <img className='w-full h-full border p-1 rounded-2xl'
                        src={e.image} alt="" />
                    <a className='border-b-[0.5px] w-full' href="">Вебсайт линк:</a>
                    <a className='border-b-[0.5px] w-full' href="">Админ хуудсын линк:</a>
                </div>
            ))}
            <Link to={'/shop'} className='w-[25vw] h-[30vh] max-md:w-[42vw] max-md:h-[15vh] border p-1 rounded-2xl bg-[#f0f0f0] flex justify-center items-center'>
                <IoBagAddOutline size={40} />
            </Link>
        </div>
    )
}

export default OwnedWeb
