import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import ShopPage from './Pages/Shop/Shop';
import PricePage from './Pages/Price/Price';
import ContactPage from './Pages/Contact/Contact';
import LoginPage from './Pages/Login/Login';
import SignUpPage from './Pages/SignUp/SignUp';
import ProductsDetail from './Pages/Products/ProductsDetail/ProductsDetail';
import ProfilePage from './Pages/Profile/ProfilePage';
import SearchPage from './Pages/Search/SearchPage';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CartProvider } from './Context/CartContext';
import ComingPage from './Pages/Coming/ComingPage';

function App() {
  const userInfo = JSON.parse(window.localStorage.getItem('usersnk'));
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const url = 'https://templateapi.xyz/template'
    if (isLoading) {
      Promise.all([
        axios.get(url + '/categories'),
        axios.get(url + '/product'),
      ])
        .then(([categories, products]) => {
          setCategories(categories.data.data);
          setProducts(products.data.data);
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false))
    }
  }, [isLoading]);


  return (
    <div className='flex justify-center items-center flex-col '>
      <CartProvider>
        <Router>
          <Navbar />
          <Routes>
            {/* <Route path='/' element={<ComingPage />} /> */}
            <Route path='/' element={<Home products={products} />} />
            <Route path='/shop' element={<ShopPage products={products} />} />
            <Route path='/price' element={<PricePage categories={categories} />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/product/:id' element={<ProductsDetail products={products} />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/search' element={<SearchPage products={products} />} />
          </Routes>
        </Router>
      </CartProvider>
    </div>
  );
}

export default App;
