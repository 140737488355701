import React, { useEffect, useState } from 'react';
import PricePlanCard from '../../Utills/PricePlanCard';
import axios from 'axios';

const AvailablePlan = () => {
    const [prices, setPrices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activePlans, setActivePlans] = useState([]);

    const colors = [
        { colorfrom: '#22BDFF', colorto: '#91FFDE' },
        { colorfrom: '#22BDFF', colorto: '#5D6DFF' },
        { colorfrom: '#22BDFF', colorto: '#FFEF5D' },
        { colorfrom: '#8380FE', colorto: '#FF4848' },
    ];

    useEffect(() => {
        if (isLoading) {
            Promise.all([
                axios.get('https://templateapi.xyz/template/acitveplan'),
                axios.get('https://templateapi.xyz/template/price'),
            ])
                .then(([active, price]) => {
                    setPrices(price.data.data);
                    setActivePlans(active.data.data);
                })
                .finally(() => setIsLoading(false));
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="flex w-full flex-wrap gap-10 justify-start">
            {activePlans.map((plan, index) => (
                <PricePlanCard
                    isUsed={plan.isUsed}
                    isAvailablePlan={true}
                    expireDate={plan.expireDate}
                    colorfrom={colors[index].colorfrom}
                    colorto={colors[index].colorto}
                    {...prices.find((e) => e._id === plan.planId)}
                />
            ))}
        </div>
    );
};

export default AvailablePlan;
