import React, { useEffect, useState } from 'react';
import { FaStar } from "react-icons/fa";
import PrimaryButton from './Button/PrimaryButton';
import { CiShoppingCart } from "react-icons/ci";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BsCheckCircleFill } from "react-icons/bs";

const ProductCard = ({ title, rating, _id, images, setFavorites, favorites }) => {
    const [img, setImage] = useState('');
    const user = JSON.parse(window.localStorage.getItem('template_user'));

    useEffect(() => {
        const firstImage = images?.[0]?.image;
        setImage(firstImage ? `https://templateapi.xyz/template/uploads/${firstImage}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn0hr3Zv9xFM6-psSAqxrjsdlB-tfcYb_Juw&s');
    }, [images]);

    const saveFavorite = () => {
        // const url = 'https://templateapi.xyz/template';
        const url = 'https://templateapi.xyz/template';

        axios.post(url + '/favorite', {
            userId: user._id,
            productId: _id,
            images: img,
            title: title,
        }).then((e) => {
            setFavorites(prevFavorites => [...prevFavorites, e.data.data]);
        });
    }
    const removeFavorite = () => {
        // const url = 'https://templateapi.xyz/template';
        const url = 'https://templateapi.xyz/template';

        axios.delete(url + '/favorite/' + favorites.find((e) => e.productId === _id)?._id, {
            data: {
                userId: user._id,
                productId: _id,
            },
        }).then(() => {
            setFavorites(prevFavorites => prevFavorites.filter(favorite => favorite.productId !== _id));
        });
    }


    return (
        <div className="border-[1px] border-[#22BDFF] p-3 w-[15vw] h-[35vh] rounded-lg flex flex-col justify-center items-start gap-4
    max-md:w-[90vw] max-md:h-[40vh]">
            <img className='border-[1px] border-[#bce9fc] p-3 w-[15vw] rounded-lg h-[15vh] self-center max-md:w-[90vw] max-md:h-[20vh]'
                src={img} alt="" />
            <h2>{title}</h2>
            <div className='flex justify-center items-center gap-2'>
                <FaStar size={23} color='#22BDFF' />
                <p className='text-[#22BDFF]'>{rating}</p>
                <p className='text-[#838383] text-[14px]'>(12 сэтгэгдэл)</p>
            </div>
            <div className='flex justify-between items-center w-full max-md:w-[80vw]'>
                <Link to={'/product/' + _id}>
                    <PrimaryButton width={window.innerWidth < 720 ? '30vw' : '8vw'} text={'Зочлох'} />
                </Link>
                <div className='bg-[#333] p-1 rounded-full'>
                    {favorites.filter((e) => e.productId === _id).length === 0 ?
                        <CiShoppingCart onClick={saveFavorite} size={30} color='#22BDFF' />
                        :
                        <BsCheckCircleFill onClick={removeFavorite} size={30} color='green' />
                    }
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
