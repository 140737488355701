import React, { useEffect, useState } from 'react'
import Rating from './Rating'
import { Link } from 'react-router-dom';

const ShopProductCard = ({ product }) => {
    const [img, setImage] = useState('');

    useEffect(() => {
        const firstImage = product.images?.[0]?.image;
        setImage(firstImage ? `https://templateapi.xyz/template/uploads/${firstImage}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn0hr3Zv9xFM6-psSAqxrjsdlB-tfcYb_Juw&s');
    }, [product]);

    return (
        <Link to={'/product/' + product._id}>
            <div className='w-[15vw] h-[35vh] flex flex-col justify-between max-md:w-[45vw] shadow-sm'>
                <img className='w-full rounded-lg' src={img} alt="" />
                <p className='text-[#838383] max-md:text-[13px]'>{product.title}</p>
                <p className='font-semibold text-[21px] max-md:text-[16px]'>{product.title}</p>
                <div className="divider"></div>
                <div className='flex gap-5 justify-start items-center'>
                    <Rating />
                    <p className='text-[18px] text-[#22BDFF]'>{product.rating}</p>
                    {window.innerWidth > 720 ? <p className='text-[14px] text-[#838383]'>(12 сэтгэгдэл)</p> : null}
                </div>
            </div>
        </Link>
    )
}

export default ShopProductCard
