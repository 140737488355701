import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import { BsEnvelopeCheck } from "react-icons/bs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ActivePlanModal({ open, handleClose, date, price, title, id }) {
    const user = JSON.parse(window.localStorage.getItem('template_user'));
    const [isSucess, setIsSuccess] = React.useState(false);

    const activeplan = () => {
        axios.post('https://templateapi.xyz/template/acitveplan', {
            userId: user._id,
            userPhone: user.phone,
            planId: id,
            title: title,
            mount: date,
            expireDate: dayjs().add(date, 'month'),
        }).then(() => {
            axios.post('https://templateapi.xyz/template/msg', {
                phone: 89257078,
                message: `Tand ${user.phone} dugaartai hereglegch zahialga hiisen baina ${dayjs().add(8, 'hours').format('YYYY-MM-DD')}`
            })
                .then(() => setIsSuccess(true))
                .catch((e) => console.log(e))
        })
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className='flex flex-col gap-5'>
                            <div className='flex justify-between'>
                                <p className='text-2xl font-bold'>{title}</p>
                                <p className='text-2xl font-bold cursor-pointer'>X</p>
                            </div>
                            {isSucess ?
                                <div className='flex gap-10 flex-col justify-center items-center'>
                                    <BsEnvelopeCheck color='green' size={100} />
                                    <p className=' text-[green]'>Таны хүсэлтийг шалгаад эргээд танд хариу өгөх болно баярлалаа</p>
                                </div>
                                : <p>
                                    Та төлбөр болох {Intl.NumberFormat('en-us').format(date * price)}₮ төгрөгийг
                                    Хаан банкны 5076883220 / Золбоотбааатар / дансанд гүйлгээний утга өөрийн дугаарыг байршуулж илгээнэ үү !!
                                    Бид шалгаад тантай холбоо барих болно.
                                </p>}
                            {isSucess ?
                                <Button onClick={handleClose} style={{ background: '#333' }} variant='contained'>Хаах</Button>
                                : <Button onClick={activeplan} style={{ background: '#333' }} variant='contained'>Төлбөр шилжүүлсэн</Button>}
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}