import React, { useEffect, useState } from 'react'
import PriceCategories from '../../Components/Price/PriceCategories'
import PricePlan from '../../Components/Price/PricePlan';
import axios from 'axios';

const PricePage = ({ categories }) => {
    const [isActive, setIsActive] = useState(0);
    const [prices, setPrices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://templateapi.xyz/template/price')
                .then((e) => setPrices(e.data.data))
                .finally(() => setIsLoading(false));
        }
    }, [isLoading])

    // alert(prices.length);

    if (isLoading) {
        return <div className="loader-container"><div className="loader"></div></div>
    }


    return (
        <div className='p-10 w-[95vw] min-h-screen flex flex-col items-start justify-start gap-5 pt-20 max-md:px-0'>
            <h2 className='text-[25px] font-semibold'>Өөрийн нөхцөлд таарах багц аа сонгоно уу</h2>
            <div className='w-[90vw]'>
                <div className="divider"></div>
            </div>
            <PriceCategories categories={categories} isActive={isActive} setIsActive={setIsActive} />
            {isActive ? <PricePlan prices={prices.filter((e) => e.category === isActive)} /> :
                <><p className='text-center w-full text-2xl text-[red]'>
                    Та вебсайтын төрлөө сонгоно уу!!!
                </p></>
            }
        </div>
    )
}

export default PricePage
