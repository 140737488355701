import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export default function CustomSnackbar({ children, color }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClick}>{children}</Button>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={color}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Та эхлээд нэвтэрнэ үү !!!
                </Alert>
            </Snackbar>
        </div>
    );
}




// const loginbody = {
//     name: name,
//     email: email,
//     phone: phone,
//     password: password,
// }

// if (isSign) {
//     if (password === passagain) {
//         axios.post('https://templateapi.xyz/template/user/register', signbody)
//             .then(() => {
//                 <NotficationSnackBar color={'success'} msg={'Амжилттай бүртгэлээ'} isOpen={true} />
//             })
//     } else {
//         <NotficationSnackBar color={'danger'} msg={'Нууц үг ижил байх ёстой'} isOpen={true} />
//     }
// } else {
//     axios.post('https://templateapi.xyz/template/user/login', loginbody)
//         .then(() => {
//             <NotficationSnackBar color={'success'} msg={'Амжилттай нэвтрэлээ'} isOpen={true} />
//         })
//         .catch(() => <NotficationSnackBar color={'error'} msg={'Нууц үг эсвэл и-мэйл буруу байна'} isOpen={true} />)

// }