import React, { useState } from 'react'
import ManyImage from '../../Utills/ManyImage'
import SecondaryButton from '../../Utills/Button/SecondaryButton'
import PrimaryButton from '../../Utills/Button/PrimaryButton'
import { MdFavoriteBorder } from "react-icons/md";
import { TiStarFullOutline } from "react-icons/ti";
import { useCart } from '../../Context/CartContext';
import { IoHeart } from "react-icons/io5";
import UseActivePlanModal from '../../Utills/Modal/UseActivePlanModal';

const ProductAbout = ({ product }) => {
    const { addCardToCart, cart, removeFromCart } = useCart();
    const [isModal, setIsModal] = useState(false);
    // alert(cart.length);

    const addCart = () => {
        const cartData = {
            title: 'lalar',
            img: 'https://templateapi.xyz/template/uploads/' + product.images[0].image,
            id: product._id
        };

        addCardToCart(cartData);
    }

    const removeCart = () => {
        removeFromCart(product._id);
    }

    return (
        <>
            {isModal ? <UseActivePlanModal open={isModal} img={product.images[0].image} productId={product._id}
                handleClose={() => setIsModal(false)} /> : null}
            <div className='flex gap-10 max-md:flex-col max-md:w-full'>
                <ManyImage images={product.images} />
                <div className='w-[40vw] flex flex-col gap-5 max-md:w-full'>
                    <div>
                        <h2 className='text-[30px] font-semibold'>{product.title}</h2>
                        <div className='flex gap-3'>
                            {Array(product.rating ? parseInt(product.rating) : 0).fill().map((_, index) => (
                                <p key={index}>
                                    <TiStarFullOutline size={20} color='#FFD700' />
                                </p>
                            ))}

                            <p className='text-[#22BDFF]'>{product.rating}</p>
                            <p className='text-[#838383] text-[14px]'>(12 сэтгэгдэл)</p>
                        </div>
                    </div>
                    <div>
                        <p className='text-[#838383] text-[14px]'>Худалдан авах үнэ</p>
                        <h2 className='text-[32px] font-bold'>{Intl.NumberFormat('en-us').format(product.price)}</h2>
                    </div>
                    <a href={product.url}>
                        <SecondaryButton text={'Тест хувилбар үзэх'} width={'40vw'} color={'#333'} />
                    </a>
                    <div>
                        <div className='w-full flex gap-2 justify-between'>
                            <PrimaryButton onClick={() => setIsModal(true)} text={'Түрээслэх'} width={'90%'} bg={'#333'} />
                            <div className='bg-[#333] rounded-lg p-2 self-center'>
                                {cart.filter((e) => e.id === product._id).length > 0 ?
                                    <IoHeart onClick={removeCart} color='red' size={20} /> :
                                    <MdFavoriteBorder onClick={addCart} size={20} color='#fff' />
                                }
                            </div>
                        </div>
                        <p className='py-2 text-[#333] text-[14px]'>Илүү их бэлэг, Илүү хямд</p>
                    </div>
                    <p className='text-[15px]'>{product.description}</p>
                    <div>
                        <h2 className='text-[21px] font-semibold'>Вебсайт дээрх үйлдэлүүд</h2>
                        <p className='text-[14px] text-[#333] px-5'>-Админ хэсэг</p>
                        <p className='text-[14px] text-[#333] px-5'>-Влог хуудас</p>
                        <p className='text-[14px] text-[#333] px-5'>-Бидний ажилууд хуудас</p>
                        <p className='text-[14px] text-[#333] px-5'>-Мэдээлэл орох хуудас</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductAbout
